// S11 MODAL ----------------------------------------

.modal1 {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal1:checked~.modal1 {
  opacity: 1;
  visibility: visible;
}

.contenedor1 {
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

@media (max-width: 1400px) {
  .contenedor1 {
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor1 {
    width: 100vw;
    height: 100%;
  }
}

#btn-modal1:checked~.modal1 .contenedor1 {
  transform: translateX(0%);
  display: flex;
}

.contenedor1 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor1 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}

@media (max-width:720px) {
  .contenedor1 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal1 {
  display: none;
}

.modalV {
  position: absolute;
  top: -40000px;
  left: -40000px;
}

.pointer {
  cursor: pointer;
}

// S9 MODAL ----------------------------------------------------------

.modal2 {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal2:checked~.modal2 {
  opacity: 1;
  visibility: visible;
}

.contenedor2 {
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

}

@media (max-width: 1400px) {
  .contenedor2 {
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor2 {
    width: 100vw;
    height: 100%;
  }
}

#btn-modal2:checked~.modal2 .contenedor2 {
  transform: translateX(0%);
  display: flex;
}

.contenedor2 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor2 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}


@media (max-width:720px) {
  .contenedor2 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal2 {
  display: none;
}

// S8 NEW MODAL ---------------------------------------------

.modal3 {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal3 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal3:checked~.modal3 {
  opacity: 1;
  visibility: visible;
}

.contenedor3 {
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

}

@media (max-width: 1400px) {
  .contenedor3 {
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor3 {
    width: 100vw;
    height: 100%;
  }
}

#btn-modal3:checked~.modal3 .contenedor3 {
  transform: translateX(0%);
  display: flex;
}

.contenedor3 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor3 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}


@media (max-width:720px) {
  .contenedor3 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal3 {
  display: none;
}

// S8 OLD MODAL ---------------------------------------------

.modal4{
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal4{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal4:checked~.modal4 {
  opacity: 1;
  visibility: visible;
}

.contenedor4{
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

}

@media (max-width: 1400px) {
  .contenedor4{
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor4{
    width: 100vw;
    height: 100%;
  }
}

#btn-modal4:checked~.modal4 .contenedor4 {
  transform: translateX(0%);
  display: flex;
}

.contenedor4 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor4 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}


@media (max-width:720px) {
  .contenedor4 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal4 {
  display: none;
}


// S7 EVO ------------------------------------------------------------------------

.modal5{
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal5{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal5:checked~.modal5 {
  opacity: 1;
  visibility: visible;
}

.contenedor5{
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

}

@media (max-width: 1400px) {
  .contenedor5{
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor5{
    width: 100vw;
    height: 100%;
  }
}

#btn-modal5:checked~.modal5 .contenedor5 {
  transform: translateX(0%);
  display: flex;
}

.contenedor5 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor5 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}


@media (max-width:720px) {
  .contenedor5 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal5 {
  display: none;
}


// S3 MODAL -------------------------------------------------------- 

.modal6{
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  
}

.modal6{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal6:checked~.modal6 {
  opacity: 1;
  visibility: visible;
}

.contenedor6{
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  
}

@media (max-width: 1400px) {
  .contenedor6{
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor6{
    width: 100vw;
    height: 100%;
  }
}

#btn-modal6:checked~.modal6 .contenedor6 {
  transform: translateX(0%);
  display: flex;
}

.contenedor6 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor6 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}


@media (max-width:720px) {
  .contenedor6 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal6 {
  display: none;
}



// S13 MODAL ---------------------------------------------------

.modal7{
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  
}

.modal7{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .8s;
  // opacity: 0;
  visibility: hidden;
}

#btn-modal7:checked~.modal7 {
  opacity: 1;
  visibility: visible;
}

.contenedor7{
  width: 100vw;
  height: 100%;
  transition: .8s;
  position: relative;
  transform: translateX(100%);
  display: flex;
  /* background-image: url('../../assets//catalogo/bg-color.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  
}

@media (max-width: 1400px) {
  .contenedor7{
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .contenedor7{
    width: 100vw;
    height: 100%;
  }
}

#btn-modal7:checked~.modal7 .contenedor7 {
  transform: translateX(0%);
  display: flex;
}

.contenedor7 label {
  position: absolute;
  left: 100px;
  bottom: 30px;
  cursor: pointer;
  font-size: 2em;
  z-index: 9999;
  color: green;
  transition: .5s;
}

.contenedor7 label:hover {
  left: 80px;
  bottom: 30px;
  transition: .5s;
}


@media (max-width:720px) {
  .contenedor7 label {
    left: 35px;
    bottom: 20px;
  }
}

#btn-modal7 {
  display: none;
}