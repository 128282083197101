
.slideMobile{
  @media (min-width:440px) {
    display: none;
    
  }
}

.Slide{
  @media (max-width:440px) {
    display: none;
   
  }
}
.swiper {
    
    width: 95%;
    height: 500px;
    display: flex;
    
    @media (max-width:440px) {
      // background-color: red;
      margin-top: 30%;
}
}


.swiper-slide {
    // top: -150px; 
    
    
    text-align: center;
    font-size: 18px;
    height: 900px;
   
    
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    
}










@mixin flex-center { display: flex; justify-content: center; align-items: center; }

.glassBox {
    &, & * { box-sizing: border-box; transition: 400ms; }
    
    width: 100%;
    height: 400px;
    max-width: 300px;
    border-radius: 5px;
    background: rgba(155, 155, 155, 0.05);
    backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    padding: 15px;
    position: relative;
    @include flex-center;
    flex-direction: column;

 
  
    &__imgBox {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &__title {
      text-align: center;
      margin-top: 15px;
      color: rgb(0, 0, 0);
      font-size: 20px;
      font-weight: 400;
      // font-family: sisco;
      font-weight: 700;
    }

   
    &__content {
      position: absolute;
      right: 15px; bottom: 15px; left: 15px;
      text-align: center;
      color: rgb(0, 0, 0);
      font-size: 14px;
      
      letter-spacing: 5em;
      opacity: 0;
    }
    &:hover &__imgBox {
        
      transform: translateY(-50px);
    }
    &:hover &__imgBox img {
      transform:  scale(1.4);
     
      
    }

    &:hover &__imgBox h2{
      color: rgb(0, 105, 180);
      transition: .6s;
     
      
    }
//     &:hover &__title {
//       //margin-top: 40px;
//     }
//   }
}

.glassBox__imgBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow img{
  width: 150px;
  align-items: center;
  justify-content: center;
  
}


