.slider-features {
    width: 100vw;
    height: 100vh;
}


.outer-wrapper {
    
    height: 100vw;
    width: 100vh;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    // overflow-x: hidden;
    position: absolute;

    -ms-overflow-style: none;
    background: rgb(252, 252, 252);
    background: linear-gradient(221deg, rgba(252, 252, 252, 1) 0%, rgba(217, 217, 217, 1) 100%);
    
}

.outer-wrapper::-webkit-scrollbar{
    width: 10px;
    
}
.outer-wrapper::-webkit-scrollbar-thumb{
    background: rgb(0, 105, 180);
    border-radius: 50px;
}


.direction {
    display: flex;
    align-items: center;
    justify-content: center;

}

.slide {
    height: 100vw;
    


}


.one {
    // border: 2px solid red;
}

.two {
    // border: 2px solid red;
}

.three {
    // border: 2px solid red;
}

.features-container {
    // border: 2px solid rgb(40, 226, 16);
    height: 610px;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // margin-left: 110px;
    
}

.title-container {
    display: flex;
    // flex-direction: column;
    // align-items: center;
    // height: 610px;
    align-items: center;
    justify-content: space-between;
    width: 1300px;
}

.title-container-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.div-title{
    width: 500px;
    text-align: center;
}

.title-features {
    display: inline-flex;
    // justify-content: center;
    // align-items: center;
    // border: 1px solid blue;
    // height: 610px;
    
}


.title-features h2 {
    font-size: 200px;
    
}

.title-featuress8 h2{
    font-size: 130px;
}


.scs-features {
    // border: 1px solid blue;
    width: 505px;
    height: 450px;
    background-image: url('../../assets/productos/bgitems.png');
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.scs-features img {
    width: 95%;
    
}

.features-img {
    width: 550px;
    // height: 610px;
}

.features-img img{
    
    width: 100%;
}





.d-text {
    width: 1450px;
    text-align: center;
    font-size: 22px;
}



.desktop{
@media (max-width:1025px) {
    display: none;
}
}
// SECTION 3 


.life-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1500px;
    
}


.life-title h2 {
    font-size: 80px;
    
}

.life-img-features {
    width: 815px;
    height: 810px;
}

.life-img-features img{
    width: 100%;
    height: 100%;
}


@media (max-width: 1600px) {
    .features-container {
        // border: solid 1px blue;
        height: 540px;
        width: 1150px;
        justify-content: center;

    }

    .title-container {
        
        height: 540px;
    }

    .title-features span {
        font-size: 210px;
        color: rgb(0, 105, 180);
    }

    

    .title-featuress8 h2{
        font-size: 100px;
    }

    .scs-features {
        width: 400px;
        height: 350px;
    }

    .features-img {
        width: 480px;
        
    }

    .features-img img{
        width: 100%;
    }

    .d-text {
        width: 950px;
        text-align: center;
        font-size: 18px;

    }

    .life-title h2 {
        font-size: 60px;
    }

    .life-img-features {
        width: 650px;
        height: 655px;
    }

    .life-img-features img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width:1025px) {
    .mobile{
        display: none;
    }
}

@media (max-width: 1025px) {

    .scs-features{
        background-image: url('../../assets/productos/bgItemsMobile.png');
    }
    .features-container {
        // border: solid 1px blue;
        // height: 540px;
        // width: 1200px;
        justify-content: center;
        align-items: center;
        // margin-left: 110px;

    }

    .title-container {
        height: 540px;
    }

    .title-features h2 {
        font-size: 210px;
        
    }

    .scs-features {
        width: 634px;
        height: 750px;
        
    }

    .scs-features img{
        width: 650px;
    }

    .features-img{
        width: 650px;
        
    }

    .features-img img {
        width: 100%;
        
    }

    

    .d-text {
        // border: 1px solid red;
        width: 650px;
        text-align: center;
        font-size: 20px;

    }

    .life-title{
        align-items: center;
        justify-content: center;
    }

    .life-title h2 {
        font-size: 60px;
        width: 650px;
        text-align: center;
        
    }
    
    .life-img-features {
        display: flex;
        width: 650px;
        
    }

    .life-img-features img {
        width: 100%;
        height: 100%;
    }

    .title-featuress8 h2{
        font-size: 150px;
    }
}







@media (max-width:768px) {
    .features-container {
        width: 500px;
    }

   

    

    .title-features h2{
        font-size: 140px;
    }

    .scs-features{
        height: 300px;
        background-size: 250px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .scs-features img{
        width: 380px;
    }
    .d-text {
        width: 550px;
        text-align: center;
        font-size: 18px;
    }
    .life-title h2 {
        font-size: 80px;    
    }
    .life-img-features {
        margin-top: 40px;
        width: 380px;  
    }

    .title-featuress8 h2{
        font-size: 60px;
    }
    
}

@media (max-width:450px) {

    
    
    .features-container{
        width: 390px;
        justify-content: center;
        align-items: center;
    }

    

    .features-img {
        width: 340px;
        height: 539px;
    }

   
    .d-text {
        width: 350px;
        text-align: center;
        font-size: 18px;

    }

    .life-title{
        width: 300px;
        text-align: center;
    }

    .life-title h2 {
        font-size: 50px;
        
    }
    
}


// CUSTOM CONFIG

.text-gradiant span{
    font-size: 90px;
    animation: fluido 10s ease-in-out infinite;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 300%;
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width:480px) {
        font-size: 60px;
    }
  }
  
  @keyframes fluido {
      0% { background-position: 0 50% }
      50% { background-position: 100% 50% }
      100% { background-position: 0 50% }

      
  }

  


