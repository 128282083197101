.livery{
    position: fixed;
    top: 35%;
    left: 1%;
    z-index: 10;

    @media (max-width:1024px) {
        display: none;

    }

    img{
        width: 80px;
        animation: updown 1.5s linear infinite;
    }

    @keyframes updown {
        0% {
            transform: translateY(-10px);
        }
        50% {
            transform: translateY(10px);
        }
        100% {
            transform: translateY(-10px);
        }
}

}