  .destacadosMobileContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 320px;

  

    @media screen and (min-width:1025px) {
      display: none;

    }

    @media screen and (max-width: 1024px) {
      margin-top: -100px;
    }

    @media screen and (max-width:480px) {
      // margin-top: 120px;
      margin-bottom: 100px;
    }
  }

  .mobileTamaño {
    margin-bottom: 20px;

    img {
      height: 760px;
      border-radius: 30px;

      @media (max-width:480px) {
        height: 410px;
      }

      @media (max-width:400px) {
        height: 390px;
      }
    }
  }

  .S13Mobile {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .div-s13 {
    width: 680px;
    height: 700px;
    border-radius: 30px;
    // border: 1px solid red;
    background-image: url('../../assets/img/mobiles13-bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 130px;

    .slide-s13{
      padding-top: 10px;
      // border: 1px solid blue;
      width: 420px;
      height: 200px;

      @media (max-width:480px) {
        width: 230px;
        
        padding-top: 50px;
      }
     
    }

    


    @media (max-width:480px) {
      width: 340px;
      height: 400px;
      margin-bottom: 20px;
    }
  }

  .S11Mobile:nth-child(2) .S8Mobile:nth-child(3).S7Mobile:nth-child(4) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }

  // 

