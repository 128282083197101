.livery_movil{
    position: fixed;
    top: 35%;
    left: 0;
    z-index: 99;

    @media (min-width: 1024px) {
        display: none;
    }

}