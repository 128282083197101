
    

    .distribuidoresContent {
        padding-top: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;
        flex-wrap: wrap;
        margin-bottom: 60px;
        

        @media (max-width: 1280px) {
            padding-top: 245px;
        }
        
        @media (max-width: 1024px) {
            padding-top: 10px;
        }

       
       
        
        
    }

    


    .info-form{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .infoDistri {
        // border: solid 3px red;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 1520px;
        padding: 80px;
        border-radius: 20px;
        background-color: rgb(244, 244, 244);
        -webkit-box-shadow: -2px 12px 15px -7px rgba(0, 0, 0, 0.36);
        -moz-box-shadow: -2px 12px 15px -7px rgba(0, 0, 0, 0.36);
        box-shadow: -2px 12px 15px -7px rgba(0, 0, 0, 0.36);
        @media (max-width:1600px) {
            width: 1200px;
        }
        @media (max-width:1280px) {
            width: 1000px;
        }
        @media (max-width:1024px) {
            width: 655px;
            padding: 70px;
        }

        @media (max-width:440px) {
            width: 365px;
            padding: 40px;
        }
    }

    .infoTitle {
        text-align: center;
        

        h2 {
            font-size: 40px;
            color: black;

            @media (max-width:1024px) {
                font-size: 25px;
            }
            
        }

        

    }

    .infoContacto {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        // width: 90%;

        @media (max-width:1024px) {
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
        }

        @media (max-width:440px) {
            
            
            
        }

        p {
            font-size: 20px;
            color: black;
            user-select: all;

            @media (max-width:768px) {
                
            }

            @media (max-width:440px) {
                font-size: 18px;
            }
        }
        
        

        .info p{
            margin-top: 30px;
        }

        .formulario{
            margin-top: 30px;
        }


    }
