

.novedades_content{
    

    display: flex;
    justify-content: center;
    // flex-wrap: wrap;
    // position: relative;
    @media (max-width: 1024px) {
        display: none;
    }
    
}

.novedades_box{
    width: 1530px;  
    height: 895px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // background-image: url(../../assets/img/s13_div_background.png);
    background-repeat: no-repeat;
    background-position:fixed;
    background-size: 100%;

    .coming-img{
        width: 500px;
        position: absolute;
        top: -90px;
        left: -125px;
        right: 0;
        bottom: 0;

        @media (max-width:1600px) {
            left: -120px;
            width: 400px;
        }

        @media (max-width:1600px) {
            left: -90px;
            width: 350px;
        }

        img{
            width: 100%;
        }
    }

    .novedades-img img{
        width: 1560px;
    }

    @media (max-width:1600px) {
        width: 1200px;
        height: 710px;

        .novedades-img img{
            width: 1235px;
        }

       
    }
    @media (max-width:1280px) {
        width: 1000px;
        height: 600px;
        .novedades-img img{
            width: 1020px;
        }
    }
  
}



// .s8{
//     // position: relative;
//     // top: 240px;
//     // left: 190px;
//     // display: block;
//     @media (max-width: 1600px) {
//         width: 500px;
//         top: 10px;
//         left: 200px;
//       }
//     @media (max-width:1280px) {
//         left: 130px;
//         width: 450px;
//     }
//     img{
//         width:100%;
//         @-webkit-keyframes animar{
// 			0%{-webkit-transform:translate(0px,0px);}
// 			10%{-webkit-transform:translate(20px,0px);}
// 			20%{-webkit-transform:translate(0px,0px);}
// 			30%{-webkit-transform:translate(20px,0px);}
// 			40%{-webkit-transform:translate(0px,0px);}
// 			50%{-webkit-transform:translate(20px,0px);}
// 			60%{-webkit-transform:translate(0px,0px);}
// 			70%{-webkit-transform:translate(20px,0px);}
// 			80%{-webkit-transform:translate(0px,0px);}
// 			90%{-webkit-transform:translate(20px,0px);}
// 			100%{-webkit-transform:translate(0px,0px);}
// 		}
       
//     }



// }

// VERSIÓN MÓVIL



.novedades_content_mobile{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // flex-wrap: wrap;
    width: 100%;    
    margin-bottom: 150px;
    @media (min-width: 1025px) {
        display: none;
    }
}

.novedades_box_mobile{
    width: 670px;

    @media (max-width:480px) {
        width: 345px;
    }

    img{
        width: 100%;
    }
}
