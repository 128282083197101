.products{
    display: block;
    top: -350px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    
    
    
}

