.Gallery{

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: normal;
  align-content: normal;
  
  

.containerGallery {
    // margin-top: px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 10vmin;
    // flex-direction: column;
    overflow: hidden;
    // transform: skew(5deg);
    position: relative;
    top: -5em;
    width: 1525px;
    margin-bottom: -100px;
    left: 4px;

    @media (max-width: 1600px) {
      width: 1200px;
    }
    
    
    
    @media (max-width:1280px) {
      margin-bottom: -260px;
      top: -7em;
      width: 998px;
      left: 3px;
      
    }


    @media (max-width:1024px) {
      
      top: -30em;
      width: 680px;
      
    }

    @media (max-width: 440px) {
      top: -10em;
      margin-bottom: -100px;
      width: 365px;
      
      
    }
  
    .card {
      
      flex: 1;
      transition: all 1s ease-in-out;
      height: 75vmin;
      position: relative;
      border-radius: 25px;

     img{
      border-radius: 25px;

      @media (max-width:480px) {
        border-radius: 10px;
      }
     }
      
      .card__head {
        color: white;
        background: #0069B4;
        padding: 0.5em;
        transform: rotate(-90deg);
        transform-origin: 0% 0%;
        transition: all 0.5s ease-in-out;
        min-width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 2em;
        white-space: nowrap;
        
      }
  
      &:hover {
        flex-grow: 10;
        img {
          filter: grayscale(0);
        }
        .card__head {
          text-align: center;
          top: calc(100% - 2em);
          color: white;
          background: rgba(0, 0, 0, 0.5);
          font-size: 2em;
          transform: rotate(0deg) skew(-5deg);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease-in-out;
        filter: grayscale(100%);
      }
      &:not(:nth-child(5)) {
        margin-right: 1em;
      }
    }



  }
  

}