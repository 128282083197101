.contact-container {
    font-size: 1.5em;
    display: flex;
    width: 400px;
    height: 450px;
    border-radius: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background: #ecf0f3;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white; 
}

.contact-content {
    width: 300px;
    // height: 400px;
}

.form-contact {
    display: flex;
    flex-direction: column;
}

.message {
    height: 150px;
    display: flex;
    justify-content: start;
}

.text-area {
    height: 100px;
    width: 100%;
    resize: none;
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    background: #ecf0f3;

}

.input-form {
    margin-bottom: 30px;
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    background: #ecf0f3;
    padding: 10px;
    padding-left: 20px;
    height: 50px;
    border-radius: 10px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}


.form-contact{
    align-items: center;
}
.form-contact button{
    margin-top: 25px;
    border-radius: 5px;
    width: 120px;
    height: 30px;
    background-color: #0069B4;
    color: white;
    border: none;
    transition: .4s;
}

.form-contact button:hover{
    background-color: #0160a3;
    transition: .4s;
}

@media (max-width:480px) {
    .contact-container {
       width: 350px;
    }

    .contact-content {
        width: 260px;
        // height: 400px;
    }
    
}