


.NavbarMobile{
    position: fixed;
    z-index: 9999;
    
    
    
    
    .logoNav{
        position: relative;
        z-index: 9999;
        top: -4em;
        width: 300px;
        
      img{
        // background-color: red;
        width: 100%;
        
      }
    }
  
      
      // [type="checkbox"]:checked,
      // [type="checkbox"]:not(:checked){
      //   position: absolute;
      //   left: -9999px;
      // }
      .menu-icon:checked + label,
      .menu-icon:not(:checked) + label{
        position: fixed;
        top: 63px;
        right: 75px;
        display: block;
        width: 30px;
        height: 30px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        z-index: 10;
        
      }
      .menu-icon:checked + label:before,
      .menu-icon:not(:checked) + label:before{
        position: absolute;
        content: '';
        display: block;
        width: 30px;
        height: 20px;
        z-index: 20;
        top: 0;
        left: 0;
        border-top: 2px solid #ececee;
        border-bottom: 2px solid #ececee;
        transition: border-width 100ms 1500ms ease, 
                    top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
                    height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), 
                    background-color 200ms ease,
                    transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      .menu-icon:checked + label:after,
      .menu-icon:not(:checked) + label:after{
        position: absolute;
        content: '';
        display: block;
        width: 22px;
        height: 2px;
        z-index: 20;
        top: 10px;
        right: 4px;
        background-color: #ffffff;
        margin-top: -1px;
        transition: width 100ms 1750ms ease, 
                    right 100ms 1750ms ease,
                    margin-top 100ms ease, 
                    transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      .menu-icon:checked + label:before{
        top: 10px;
        transform: rotate(45deg);
        height: 2px;
        background-color: #040404;
        border-width: 0;
        transition: border-width 100ms 340ms ease, 
                    top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
                    height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), 
                    background-color 200ms 500ms ease,
                    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      .menu-icon:checked + label:after{
        background-color: #040404;
        width: 30px;
        margin-top: 0;
        right: 0;
        transform: rotate(-45deg);
        transition: width 100ms ease,
                    right 100ms ease,  
                    margin-top 100ms 500ms ease, 
                    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      
      
      .navMobile{
        position: fixed;
        top: 33px;
        right: 50px;
        display: block;
        width: 80px;
        height: 80px;
        padding: 0;
        margin: 0;
        z-index: 9;
        overflow: hidden;
        box-shadow: 0 8px 30px 0 rgba(0,0,0,0.3);
        background-color: #026AB5;
        animation: border-transform 7s linear infinite;
        transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),  
                    right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),
                    transform 250ms 1100ms ease,
                    width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1),
                    height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      @keyframes border-transform{
          0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
        14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
        28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
        42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
        56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
        70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
        84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
      }
      
      .menu-icon:checked ~ .navMobile {
        opacity: 0.8;
        background-color: white;
        animation-play-state: paused;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        width: 200%;
        height: 200%;
        transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),  
                    right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),
                    transform 250ms 700ms ease,
                    width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1),
                    height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      
      .navMobile ul{
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        z-index: 6;
        text-align: center;
        transform: translateY(-50%);
        list-style: none;
      }
      .navMobile ul li{
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        margin: 10px 0;
        text-align: center;
        list-style: none;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transform: translateY(30px);
        transition: all 250ms linear;
      }
      .navMobile ul li:nth-child(1){
        transition-delay: 200ms;
      }
      .navMobile ul li:nth-child(2){
        transition-delay: 150ms;
      }
      .navMobile ul li:nth-child(3){
        transition-delay: 100ms;
      }
      .navMobile ul li:nth-child(4){
        transition-delay: 50ms;
      }
      .navMobile ul li:nth-child(5){
        transition-delay: 50ms;
      }
      .navMobile ul li a{
        // font-family: 'Montserrat', sans-serif;
        font-size: 9vh;
        text-transform: uppercase;
        line-height: 1.8;
        font-weight: 800;
        display: inline-block;
        position: relative;
        color: black;
        transition: all 250ms linear;
        text-decoration: none;
      }
      .navMobile ul li a:hover{
        text-decoration: none;
        // color: white;
      }
      .navMobile ul li a:after{
        display: block;
        position: absolute;
        top: 50%;
        content: '';
        height: 2vh;
        margin-top: -1vh;
        width: 0;
        left: 0;
        background-color: #0069B4;
        opacity: 0.8;
        transition: width 250ms linear;
      }
      .navMobile ul li a:hover:after{
        width: 100%;
      }
      
      
      .menu-icon:checked ~ .navMobile  ul li {
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 350ms ease,
                    transform 250ms ease;
      }
      .menu-icon:checked ~ .navMobile ul li:nth-child(1){
        transition-delay: 1400ms;
      }
      .menu-icon:checked ~ .navMobile ul li:nth-child(2){
        transition-delay: 1480ms;
      }
      .menu-icon:checked ~ .navMobile ul li:nth-child(3){
        transition-delay: 1560ms;
      }
      .menu-icon:checked ~ .navMobile ul li:nth-child(4){
        transition-delay: 1640ms;
      }
      .menu-icon:checked ~ .navMobile ul li:nth-child(5){
        transition-delay: 1720ms;
      }
      
      
      
      .logo {
          position: absolute;
          top: 60px;
          left: 50px;
          display: block;
          z-index: 11;
          transition: all 250ms linear;
      }
      .logo img {
          height: 26px;
          width: auto;
          display: block;
      }
      
      
      
      @media screen and (max-width: 991px) {
        .menu-icon:checked + label,
        .menu-icon:not(:checked) + label{
          right: 55px;
        }
        .logo {
          left: 30px;
        }
        .navMobile{
          right: 30px;
        }
       
        .navMobile ul li a{
          font-size: 5vh;
        }
      }
      @media(min-width: 1024px){
        display: none;
      }
      
    }      