#destacados {
    margin-top: -300px;

    @media screen and (max-width: 1023px) {
        margin-top: -350px;
    }

    .destacados_content {
        display: flex;
        align-items: center;

        justify-items: center;
        // padding: 0 150px;



        .destacados_box {
            // background-color: red;
            width: 100%;

        }


        // S13 SECCION 

        .s13-intercomunicador {
            // border: 1px solid red;
            width: 500px;
            height: 400px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 20;
            pointer-events:none;
            background-image: url('../../assets/img/s13bg.png');
            background-repeat: no-repeat;
            // background-position: center;
            background-size: 500px;

            @media (max-width:1600px) {
                width: 440px;
                height: 370px;
                background-size: 440px;
            }

            @media (max-width:1280px) {
                width: 350px;
                height: 310px;
                background-size: 350px;
            }

        }

        

        .s11 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;

            margin-bottom: 30px;

            @media screen and (max-width:1025px) {
                display: none;
            }

        }

        .s11box2:nth-child(2) {
            display: block;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;







        }

        .s11boxposition2 {
            position: relative;
            border-radius: 30px;
            -webkit-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            -moz-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            transition: .8s;

            img {
                height: 560px;
                border-radius: 30px;

                @media (max-width:1600px) {
                    height: 440px;
                }

                @media (max-width:1280px) {
                    height: 360px;
                }
            }

            h2 {
                color: white;
                position: absolute;
                top: 30%;
                left: 5%;
                font-size: 60px;

                @media (max-width:1600px) {
                    top: 20%;
                }

                @media (max-width:1600px) {
                    top: 35%;
                    font-size: 50px;
                }

                @media (max-width: 1280px) {
                    top: 23%;
                }
            }
        }

        .s11boxposition2:hover {
            -webkit-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            -moz-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            transition: .8s;
        }

        .s11boxposition2 .s11img2hover {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: .8s;
        }

        .s11boxposition2:hover .s11img2hover {
            opacity: 1;

        }

        .s11box1:nth-child(1) {
            display: block;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;








        }

        .s11boxposition {
            margin-left: 30px;
            position: relative;
            border-radius: 30px;
            -webkit-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            -moz-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            transition: .8s;

            img {
                height: 560px;
                border-radius: 30px;

                @media (max-width:1600px) {
                    height: 440px;
                }

                @media (max-width:1280px) {
                    height: 360px;
                }
            }
        }

        .s11boxposition:hover {
            -webkit-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            -moz-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            transition: .8s;
        }

        .s11boxposition .s11imghover {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: .8s;
        }

        .s11boxposition:hover .s11imghover {
            opacity: 1;
        }

        // S8 NEW SECCION 

        .s8new {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-bottom: 30px;

            @media (max-width:1024px) {
                display: none;
            }
        }

        .s8newposition {
            position: relative;
            border-radius: 30px;
            -webkit-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            -moz-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            transition: .8s;



            img {
                height: 560px;
                border-radius: 30px;

                @media (max-width:1600px) {
                    height: 440px;
                }

                @media (max-width:1280px) {
                    height: 360px;
                }
            }

            h3 {
                color: rgb(0, 0, 0);
                position: absolute;
                top: 82%;
                left: 31%;
                font-size: 60px;
                font-weight: 600;
                z-index: 999;

                @media (max-width:1600px) {
                    left: 25%;
                }

                @media (max-width:1280px) {
                    font-size: 40px;
                    left: 30%;
                }
                

            }

            h2 {
                color: white;
                position: absolute;
                top: 82%;
                left: 25%;
                font-size: 60px;
                z-index: 999;

                @media (max-width:1600px) {
                    left: 16%;
                }

                @media (max-width:1280px) {
                    font-size: 40px;
                    left: 25%;
                }

            }


        }

        .s8newposition:hover {
            -webkit-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            -moz-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            transition: .8s;
        }

        .s8newposition .s8newimghover {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: .8s;
        }

        .s8newposition:hover .s8newimghover {
            opacity: 1;
        }

        // S7EVO SECCIÓN 

        .s7evo {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-bottom: 100px;

            @media (max-width:1024px) {
                display: none;
            }
        }

        .s7evobox1:nth-child(1) {
            display: block;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;

        }

        .s7evoposition {
            position: relative;
            border-radius: 30px;
            -webkit-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            -moz-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            transition: .8s;


            img {
                height: 560px;
                border-radius: 30px;

                @media (max-width:1600px) {
                    height: 440px;
                }

                @media (max-width:1280px) {
                    height: 360px;
                }
            }
        }

        .s7evoposition:hover {
            -webkit-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            -moz-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            transition: .8s;
        }

        .s7evoposition .s7evoimghover {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: .8s;
        }

        .s7evoposition:hover .s7evoimghover {
            opacity: 1;
        }



        .s7evobox2:nth-child(2) {
            display: block;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;

        }

        .s7evoposition2 {
            position: relative;
            margin-left: 30px;
            border-radius: 30px;
            -webkit-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            -moz-box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            box-shadow: 5px 11px 13px -1px rgba(46, 46, 46, 0.59);
            transition: .8s;


            img {
                height: 560px;
                border-radius: 30px;

                @media (max-width:1600px) {
                    height: 440px;
                }

                @media (max-width:1280px) {
                    height: 360px;
                }
            }

            h2 {
                position: absolute;
                font-size: 45px;
                width: 85%;
                color: white;
                top: 38%;
                left: 5%;

                @media (max-width: 1600px) {
                    top: 38%;
                    width: 88%;
                    font-size: 38px;
                }

                @media (max-width:1280px) {
                    top: 20%;
                    font-size: 40px;
                }
            }


        }

        .s7evoposition2:hover {
            -webkit-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            -moz-box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            box-shadow: 5px 11px 13px -1px rgba(0, 105, 180, 0.43);
            transition: .8s;
        }

        .s7evoposition2 .s7evoimg2hover {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: .8s;
        }

        .s7evoposition2:hover .s7evoimg2hover {
            opacity: 1;
        }
    }

}