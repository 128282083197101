.header {
  // border-bottom: 1px solid black;
  padding-top: 15px;
  // background-color: #fff;
  font-size: 1.8rem;
  height: 105px;
  display: flex;
  justify-content: center;
  color: $black;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 0 72px;
    font-size: 1.6rem;
  }

  @media (max-width: $layout-breakpoint-xsmall) {
    padding: 0 12px;
    height: 96px;
    font-size: 1.2rem;
  }

  .header-inner {
    background-color: white;
    // border: solid 1px rgb(193, 193, 193);
    padding: 1% 3%;
    border-radius: 10px;
    position: fixed;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99999;
    -webkit-box-shadow: 15px 13px 17px -10px rgba(0, 105, 180, 0.34);
    -moz-box-shadow: 15px 13px 17px -10px rgba(0, 105, 180, 0.34);
    box-shadow: 15px 13px 17px -10px rgba(0, 105, 180, 0.34);

    .logo {
      font-weight: 700;
      font-size: 3.6rem;

      @media (max-width: $layout-breakpoint-medium) {
        font-size: 3.2rem;
        flex: 4;
      }

      .LogoNav {
        width: 140px;
      }
    }

    .nav {
      
      

      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      
      li {
        list-style: none;
        margin: 0 40px;

        

        a {
          display: inline-block;
          position: relative;
          color: rgb(0, 0, 0);
          text-decoration: none;
            
          
        }
        a:after {
          content: "";
          display: block;
          margin: auto;
          height: 2px;
          width: 0px;
          transition: all .5s;
          }
          a:hover:after {
            width: 100%;
            background: #0069B4;
            }
          @media (max-width: 1440px) {
            margin: 0 7px;
          }
      }


      .tiendas{
        color: #0069B4;
        font-weight: 700;
        font-size: 1.5em;
      }


    }

    .contact {
      @media (max-width: $layout-breakpoint-medium) {
        
      }

      a:hover{border-bottom: 1px solid #111;} 
      h1{font-size: 2em; padding: 20px 0;}
      p{
        font-size: .75em;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 20px 0;
      }
  
      button:hover{cursor: pointer}
      button {
        // background: transparent; outline: none;
        background-color: white;
        position: relative;
        border: 1px solid black;
        padding: 10px 30px;
        overflow: hidden;
        border-radius: 10px;
      }
  
      /*button:before (attr data-hover)*/
      button:hover:before{opacity: 1; transform: translate(0,0);}
      button:before{
        content: attr(data-hover);
        position: absolute;
        top: 0.1em; left: 0;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 800;
        font-size: .8em;
        opacity: 0;
        transform: translate(-100%,0);
        transition: all .3s ease-in-out;
        color: #0069B4;
        
      }
        /*button div (button text before hover)*/
        button:hover div{opacity: 0; transform: translate(100%,0)}
        button div{
          text-transform: uppercase;
          letter-spacing: 3px;
          font-weight: 800;
          font-size: .8em;
          transition: all .3s ease-in-out;
          
        }

        // @media(max-width: 1440px){
        //   display: none;
        // }
  
    }

    .hamburger-menu {
      height: 56px;
      width: 56px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      cursor: pointer;

      &:hover {
        background: darken($color: $white, $amount: 12);
      }

      @media (min-width: $layout-breakpoint-medium) {
        display: none;
      }

      span {
        width: 20px;
        height: 3px;
        margin: 2px 0;
        background: $black;
        display: block;
      }
    }
  }
  @media(max-width: 1024px){
    display: none;
  }
}