*{
 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow-x: hidden;
  
 
  
  
  
  
}


body {

 overflow-x: hidden;
}


